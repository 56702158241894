import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import { ResetPassword } from '../components/ForgotPassword/ResetPassword'
const Login = ({ location }) => {
	const { state = {} } = location

	return (
		<Layout>
			<SEO title='Reset Password' />
			<ResetPassword state={state} />
		</Layout>
	)
}

export default Login
