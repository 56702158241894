import React, { useState, useContext } from "react";
import * as Yup from "yup";
import "./style.css";
import { Formik, Field, Form } from "formik";
import { useMutation } from "@apollo/react-hooks";
import { changePassword } from "../graphql/mutations";
import { toast } from "react-toastify";
import { navigate } from "gatsby";
import { LanguageContext } from "../../../lang";

const ResetPasswordValidationScheme = Yup.object().shape({
  password: Yup.string()
    .required("Please enter password")
    .min(5)
    .max(255)
    .label("Password"),
  cpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match")
    .required("Please Confirm Password"),
});

const intialValue = {
  password: "",
  cpassword: "",
};

const ResetPassword = ({ state }) => {
  const [changePasswordTrigger, { data, error }] = useMutation(changePassword);
  const { email, token } = state ? state : {};
  const handleResetPassword = (values) => {
    changePasswordTrigger({
      variables: {
        data: {
          email,
          token,
          password: values.password,
        },
      },
    });
  };
  if (error) {
    toast.error(error.graphQLErrors[0].message.message, {
      autoClose: 1200,
    });
  }
  if (data) {
    toast.success("Password Change Successfully", {
      autoClose: 1200,
    });
    setTimeout(() => {
      navigate("/login");
    }, 1200);
  }

  const {
    translations: { resetPassword },
  } = useContext(LanguageContext);
  const { heading, password, repeatPassword, btn } = resetPassword;

  return (
    <div className="container-fluid d-flex hm-login-bg justify-content-center align-items-center">
      <div id="forgotPassword">
        <Formik
          initialValues={intialValue}
          validationSchema={ResetPasswordValidationScheme}
          onSubmit={(values) => handleResetPassword(values)}
        >
          {({ values, errors, touched, handleSubmit }) => (
            <div className="inputstyleview">
              <Form className="formStyle">
                <div className="forgotPasswordTextView">
                  <p>{heading}</p>
                </div>

                <Field
                  className="inputStyle"
                  type="password"
                  name="password"
                  placeholder={password}
                />
                {errors.password && touched.password ? (
                  <div className="bg-danger my-1 p-0 col-12 rounded">
                    <p className="text-white p-1 m-0">{errors.password}</p>
                  </div>
                ) : null}
                <Field
                  className="inputStyle"
                  type="password"
                  name="cpassword"
                  placeholder={repeatPassword}
                />
                {errors.cpassword && touched.cpassword ? (
                  <div className="bg-danger my-1 p-0 col-12 rounded">
                    <p className="text-white p-1 m-0">{errors.cpassword}</p>
                  </div>
                ) : null}
              </Form>
              <div onClick={handleSubmit} className="loginButtonStyle">
                <p className="loginButtonText">{btn}</p>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export { ResetPassword };
